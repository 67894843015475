<div class="card border-0 mb-5" rt="machine-card" #card>
  <div class="card-image" [class.show-extra-info]="showExtraInfo">
    <swiper [config]="imageSwipeConfig" *ngIf="machine.thumbnails?.length > 0">
      <div *ngFor="let image of machine.thumbnails | slice: 0:3">
        <a
          rt="machine-card-img"
          [routerLink]="[machineDetailsUrl]"
          [queryParams]="{ id: machine.id }"
          [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
          [properties]="{ inventoryId: machine.id }"
        >
          <img class="card-img-top" *ngIf="machine.thumbnails.length == 1" [class.blur]="showExtraInfo" [src]="baseImagePath + image" />
          <img class="card-img-top swiper-lazy" *ngIf="machine.thumbnails.length != 1" [class.blur]="showExtraInfo" [attr.data-src]="baseImagePath + image" />
        </a>
      </div>
    </swiper>

    <ng-container *ngIf="machine.thumbnails?.length == 0">
      <a
        rt="machine-card-img"
        [routerLink]="[machineDetailsUrl]"
        [queryParams]="{ id: machine.id }"
        [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
        [properties]="{ inventoryId: machine.id }"
        [state]="filteredAttachments"
      >
        <img class="card-img-top" src="https://company.rubbl.com/pubweb/marketplace/images/images/machine-no-photos.png" />
      </a>
    </ng-container>

    <div class="share-icon-main-container cursor-pointer" *ngIf="!showExtraInfo" (click)="share()">
      <div class="icon-share-container">
        <i class="icon-share text-white"></i>
      </div>
    </div>

    <div class="top-owner-container" [class.extra-blur]="showExtraInfo" *ngIf="machine.isTopOwner">
      <common-top-owner [showTopOwner]="machine.isTopOwner"></common-top-owner>
    </div>
    <div
      class="machine-in-cart text-white px-3 py-1 font-weight-light w-100 d-flex align-items-center"
      style="z-index: 2"
      [class.blur]="showExtraInfo"
      [class.cursor-pointer]="hasStockPhotos && !machineInCart"
      *ngIf="machineInCart || hasStockPhotos"
      (click)="hasStockPhotos && !machineInCart && showStockPhotoInfo()"
    >
      <ng-container *ngIf="machineInCart"><i class="icon-checkmark mr-2"></i> Added to cart</ng-container>
      <div class="d-flex align-items-center cursor-pointer" *ngIf="hasStockPhotos && !machineInCart"><i class="icon-alert mr-2"></i> Stock photo</div>
    </div>

    <div class="bottom-right-container" [class.blur]="showExtraInfo">
      <div class="left-triangle"></div>
      <div class="price-container text-center" [class.blur]="showExtraInfo">
        <p class="price teko">${{ machine.rentalRate | thousandSuffix: 1 }}</p>
        <p class="per-month-text">PER MONTH</p>
      </div>
    </div>
  </div>
  <!-- start of extra info -->
  <div class="extra-info br-5 pt-3 t px-3 text-white w-100" [@fadeInOut] [style.height]="extraInfoHeight" *ngIf="showExtraInfo">
    <div class="info-icon-main-container cursor-pointer" (click)="hideExtraInfo()">
      <div class="icon-info-container">
        <i class="icon-cancel text-white fs-26"></i>
      </div>
    </div>
    <swiper [config]="extraInfoConfig" [(index)]="extraInfoIndex">
      <!-- first slide -->
      <div>
        <div class="fs-18 mb-3">Pricing Options</div>
        <p class="fs-14 font-weight-light">The following pricing options are available for this equipment.</p>
        <div class="d-flex justify-content-around font-weight-light mt-1">
          <div class="text-center" *ngFor="let rateSchedule of machine.rateSchedules | slice: 1; last as isLast; index as i">
            <p class="mb-0" *ngIf="rateSchedule.discount == 0">${{ rateSchedule.cost | thousandSuffix: 1 }}</p>
            <p class="mb-0" *ngIf="rateSchedule.discount != 0">${{ rateSchedule.cost / (rateSchedule.numDays / 28) | thousandSuffix: 1 }}</p>

            <ng-container [ngSwitch]="i">
              <p class="text-white mb-0 font-weight-light fs-13" *ngSwitchCase="0">Weekly</p>
              <p class="text-white mb-0 font-weight-light fs-13" *ngSwitchCase="1">Monthly</p>
              <p class="text-white mb-0 font-weight-light fs-13" *ngSwitchCase="2">3+ Mos</p>
              <p class="text-white mb-0 font-weight-light fs-13" *ngSwitchCase="3">6+ Mos</p>
            </ng-container>
            <span class="badge badge-pill badge-success p-1 font-weight-light" *ngIf="rateSchedule.discount != 0">{{ rateSchedule.discountPercent }}% off</span>
          </div>
        </div>
      </div>
      <!-- second slide -->
      <div *ngIf="hasStockPhotos">
        <div class="fs-18 mb-3">Stock photo</div>
        <p class="fs-14 font-weight-light">This listing contains a stock photo. All other information, including specs and pricing, is accurate.</p>
        <p class="fs-14 font-weight-light">Photos will be made available on order placement. Order cancellations are always free.</p>
        <p class="fs-14 font-weight-light">Call us at (866) 455-8531 for any questions.</p>
      </div>
      <!-- third slide -->
      <div class="px-2" *ngIf="machine.rpoEnabled">
        <common-machine-rpo-table [machine]="machine" fontClass="fs-14"></common-machine-rpo-table>
      </div>
    </swiper>
  </div>
  <!-- end of extra info -->
  <div class="text-info"></div>
  <div class="px-2" [class.blur]="showExtraInfo">
    <div class="d-flex mt-3 align-items-baseline main-info">
      <div class="main-info">
        <p class="teko machine-title">{{ machine.make }} {{ machine.model }}</p>
        <p class="font-weight-light mb-short">{{ machine.year }} {{ machine.primaryType }}</p>
        <p class="font-weight-light fs-13 mb-short mb-2">
          <ng-container *ngIf="machine.usage != 0"
            >{{ machine.usage | number: "1.0-0" }} {{ machine.usageAbbreviation || "hrs" }} <span class="mx-1">|</span></ng-container
          >

          {{ machine?.location | location: "cityState":false }}
          <ng-container *ngIf="machine.distanceFromUser">
            <span class="mx-1 d-none d-xl-inline">|</span>
            <span class="d-none d-xl-inline">{{ machine.distanceFromUser }} miles away</span>
          </ng-container>
        </p>
        <p class="d-xl-none font-weight-light fs-13 mb-2" *ngIf="machine.distanceFromUser">{{ machine.distanceFromUser }} miles away</p>
        <!-- <div class="d-inline-flex" *ngIf="machine.inventoryGroupId == ''; else freeTransport"> -->
        <div class="d-inline-flex">
          <div class="alert alert-success fs-13 p-1 font-weight-light d-flex align-items-center mb-0" role="alert" *ngIf="machine.recentCancellations == 0">
            <i class="icon-insurance mr-1"></i> {{ machine.recentCancellations }} cancels in the past 3 months
          </div>

          <div class="alert alert-warning fs-13 p-1 font-weight-light d-flex align-items-center mb-0" role="alert" *ngIf="machine.recentCancellations == 1">
            <i class="icon-notification1 mr-1"></i> {{ machine.recentCancellations }} cancel in the past 3 months
          </div>

          <div class="alert alert-danger fs-13 p-1 font-weight-light d-flex align-items-center mb-0" role="alert" *ngIf="machine.recentCancellations > 1">
            <i class="icon-notification1 mr-1"></i> {{ machine.recentCancellations }} cancels in the past 3 months
          </div>
        </div>
        <!-- <ng-template #freeTransport>
          <div class="d-inline-flex align-items-center text-green my-2">
            <div class="text-green font-weight-light">Free 1-2 day delivery</div>
          </div>
        </ng-template> -->
      </div>
      <div class="ml-auto font-weight-light">
        <div class="d-flex flex-column align-items-end">
          <span class="mb-2 text-green cursor-pointer" (click)="showPricingOptions()"
            ><u>${{ machine.rateSchedules[1].cost | thousandSuffix: 1 }} per week</u></span
          >
          <span class="badge badge-success p-1 font-weight-light d-flex cursor-pointer mb-1" (click)="showRpoInfo()" *ngIf="machine.rpoEnabled"
            ><i class="icon-info mr-1"></i> RPO Available</span
          >

          <div class="alert fs-15 p-0 px-1 font-weight-light d-flex align-items-center mb-0" role="alert">
            <i class="icon-eyeball mr-1 text-primary"></i> {{ machine.viewsTotal }} views
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1" *ngIf="machine.relatedAttachments.length > 0">
      <span
        *ngFor="let attachment of machine.relatedAttachments"
        class="badge badge-pill fs-13 p-1 font-weight-light mr-2 mb-1 px-2"
        [ngClass]="{ 'badge-secondary': attachment.selected, 'badge-outline-secondary': !attachment.selected }"
        ><i class="icon-checkmark mr-2" *ngIf="attachment.selected" style="color: #f0b823"></i>{{ attachment.displayName }} -
        {{ attachment.cost | currency: "USD":"symbol":"1.0-0" }}</span
      >
    </div>

    <div class="d-flex justify-content-center mt-2">
      <div class="w-50 d-flex">
        <!-- <button class="btn btn-outline-dark bg-white mr-2 text-dark fs-18" (click)="share()"><i class="icon-share text-primary"></i></button> -->
        <a
          rt="machine-card-view-details"
          class="btn btn-outline-dark bg-white text-dark flex-fill mr-1 d-flex justify-content-center align-items-center"
          [routerLink]="[machineDetailsUrl]"
          [queryParams]="{ id: machine.id }"
          [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
          [properties]="{ inventoryId: machine.id }"
          [state]="filteredAttachments"
          >Details
        </a>
      </div>

      <button class="btn btn-secondary btn-add-to-cart ml-1 w-50" (click)="addToCart()">
        {{ machineInCart ? "Edit" : "Add to Cart" }}
      </button>
    </div>
  </div>
</div>
