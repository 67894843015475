<div *ngIf="!showPhoneNumberInput" [class.p-4]="!inCheckout">
  <div class="alert alert-warning d-md-flex justify-content-between" role="alert" *ngIf="cartItem.status != 'Available' && !onMachineDetails">
    <div>{{ cartItem.inventoryItem.make }} {{ cartItem.inventoryItem.model }} is not available.</div>
    <u class="text-underline cursor-pointer" (click)="removeFromCart()">Remove</u>
  </div>
  <div class="d-flex fs-18">
    <div>Summary</div>
    <div class="ml-auto">{{ rateLabel }}</div>
  </div>
  <div class="bb-light mb-3 mt-1"></div>
  <!-- machine -->
  <div class="d-flex align-items-center">
    <app-thumbnail-with-x
      *ngIf="!onMachineDetails"
      [height]="inCheckout ? '125px' : '125px'"
      [width]="inCheckout ? '125px' : '125px'"
      borderRadius="5px"
      [imgSrc]="cartItem.imageThumbnail"
      [hideX]="!inCheckout || isQuote"
      (xClicked)="confirmRemoveItem()"
    ></app-thumbnail-with-x>
    <div [class.ml-2]="!onMachineDetails" style="line-height: 26px">
      <ng-container *ngIf="!onMachineDetails">
        <div class="teko fs-26" style="font-weight: 500">{{ cartItem.inventoryItem.make }} {{ cartItem.inventoryItem.model }}</div>
        <div class="font-weight-light">{{ cartItem.inventoryItem.year }} {{ cartItem.inventoryItem.primaryType }}</div>
      </ng-container>

      <ng-container *ngIf="inCheckout">
        <ng-container *ngIf="isEstimate">
          <div class="bold">Rental Rate</div>
        </ng-container>
        <ng-container *ngIf="!isEstimate">
          <div class="font-weight-light">{{ cartItem.beginDate | date: "mediumDate" }}</div>
          <div class="font-weight-light">{{ cartItem.formattedDuration }}</div>
        </ng-container>
      </ng-container>
    </div>
    <div class="ml-auto">
      <div class="fs-20 bold" *ngIf="!machineRentBeforeDiscount">{{ rateSummary?.Rent | currency: "USD":"symbol":"1.0-0" }}</div>
      <div class="fs-20 bold" *ngIf="machineRentBeforeDiscount">{{ machineRentBeforeDiscount | currency: "USD":"symbol":"1.0-0" }}</div>
      <button
        *ngIf="inCheckout && !isEstimate && !onMachineDetails && !isQuote"
        class="float-right cursor-pointer ml-auto btn btn-outline-success"
        (click)="editClick()"
      >
        Edit
      </button>
    </div>
  </div>
  <div class="bb-light my-3"></div>
  <!-- attachments -->
  <div *ngIf="cartItem.attachments.length">
    <p>Attachments</p>
    <ng-container *ngFor="let attachment of cartItem.attachments">
      <div class="d-flex mb-1">
        <div>
          <div class="font-weight-light">{{ attachment.title }}</div>
          <div class="font-weight-light mr-5 fs-14">{{ attachment.description }}</div>
        </div>
        <div class="ml-auto">{{ attachment[attachmentRateProperty] | currency: "USD":"symbol":"1.0-0" }}</div>
      </div>
    </ng-container>
    <div class="bb-light my-3"></div>
  </div>

  <!-- services -->
  <div class="d-flex">
    <div><u class="cursor-pointer" (click)="servicesTooltip.open()">Rubbl Managed Services</u></div>
    <app-questionmark-tooltip
      class="ml-2"
      [displayLearnMore]="false"
      text="A fee of 14% of your rental rate. Includes damage and liability coverage, transportation logistics, GPS tracking, and repair coordination. Minimum $149."
      #servicesTooltip
    >
    </app-questionmark-tooltip>
    <div class="ml-auto">{{ rateSummary?.PremiumServices | currency: "USD":"symbol":"1.0-0" }}</div>
  </div>
  <!-- discount -->
  <ng-container>
    <div class="bb-light my-3"></div>
    <div class="d-flex">
      <div><u class="cursor-pointer" (click)="discountTooltip.open()">Discount</u></div>
      <app-questionmark-tooltip
        class="ml-2"
        mobilePlacement="right"
        [displayLearnMore]="false"
        text="A discount is applied if your rental duration is longer than 3 months."
        #discountTooltip
      >
      </app-questionmark-tooltip>
      <div class="ml-auto text-primary">({{ discount | currency: "USD":"symbol":"1.0-0" }})</div>
    </div>
  </ng-container>
  <!-- Taxes -->
  <ng-container>
    <div class="bb-light my-3"></div>
    <div class="d-flex">
      <div><u class="cursor-pointer" (click)="salestaxTooltip.open()">Taxes</u></div>
      <app-questionmark-tooltip
        class="ml-2"
        mobilePlacement="right"
        [displayLearnMore]="false"
        text="Sales tax calculations take into account both equipment origin and destination. Sales tax is calculated during checkout."
        #salestaxTooltip
      >
      </app-questionmark-tooltip>
      <div class="ml-auto" *ngIf="rateSummary?.Tax != null">{{ rateSummary?.Tax | currency: "USD":"symbol":"1.0-0" }}</div>
      <div class="ml-auto" *ngIf="rateSummary?.Tax == null">-</div>
    </div>
  </ng-container>
  <div class="bb-light my-3"></div>
  <div class="d-flex fs-20">
    <div>{{ rateLabel }} Total</div>
    <div class="ml-auto">{{ total | currency: "USD":"symbol":"1.0-0" }}</div>
  </div>
  <!-- transportation -->
  <div class="bg-grey p-3 mt-4" style="margin-right: -20px; margin-left: -20px">
    <!--  if we have an estimated cost -->
    <!-- <ng-container *ngIf="isInventoryGroupMachine; else regular">
      <div class="d-flex">
        <div class="mr-2"><u>Transportation Round Trip</u></div>
        <div class="ml-auto text-success">Free</div>
      </div>
      <div class="mt-2">
        Machine is {{ cartItem.transportationEstimateSummary?.distance?.value | number }} miles from
        {{ cartItem?.jobLocation?.address?.postalCode || cartItem.jobZipCode }}
      </div>
      <div class="mt-2 font-italic font-weight-light fs-15">
        Free delivery is limited to 50 miles from the machine location. Additional charges may apply for longer distances.
      </div>
    </ng-container>
    <ng-template #regular> -->
    <ng-container *ngIf="cartItem.transportationEstimateSummary?.estimatedCost">
      <div class="d-flex">
        <div class="mr-2"><u>Transportation Round Trip</u></div>
        <div class="ml-auto" *ngIf="!toFarToEstimate && !toExpensiveToEstimate">
          {{ cartItem.transportationEstimateSummary?.estimatedCost * 2 | currency: "USD":"symbol":"1.0-0" }}
        </div>
        <ng-container *ngIf="toFarToEstimate || toExpensiveToEstimate">
          <div class="ml-auto"><i class="icon-notification1 fs-24 text-warning"></i></div>
        </ng-container>
      </div>
      <div class="mt-2">
        Machine is {{ cartItem.transportationEstimateSummary?.distance.value | number }} miles from
        {{ cartItem?.jobLocation?.address?.postalCode || cartItem.jobZipCode }}
      </div>
      <p class="mt-2 mb-0 font-weight-light" *ngIf="!toFarToEstimate && !toExpensiveToEstimate">
        This is just an estimate. Once an order is placed you will receive a quote within 1 business day.
      </p>
      <ng-container *ngIf="toFarToEstimate">
        <p class="mt-2 mb-0 font-weight-light">
          Rubbl does not support estimates with a distance greater then 250 miles away. Please place the order and we will provide you a quote within 1 business
          day.
        </p>
      </ng-container>
      <ng-container *ngIf="!toFarToEstimate && toExpensiveToEstimate">
        <p class="mt-2 mb-0 font-weight-light">
          Sorry, in order to provide a transportation estimate we need to take a closer look. Continue to place the order and we will provide the lowest quote
          within 1 business day.
        </p>
      </ng-container>
    </ng-container>
    <!--  if we don't have an estimated cost -->
    <ng-container *ngIf="!cartItem.transportationEstimateSummary?.estimatedCost">
      <div class="d-flex">
        <div class="mr-2"><u>Transportation Round Trip</u></div>
        <div class="ml-auto">-</div>
      </div>
      <p class="mt-3 mb-0 font-weight-light" *ngIf="!comingFromDateDurationStep">
        In order to calculate your transportation estimate, enter your zip code during add to cart.
      </p>
      <p class="mt-3 mb-0 font-weight-light" *ngIf="comingFromDateDurationStep">
        Enter your zipcode on <a class="cursor-pointer text-success" (click)="goToPreviousStep.emit()">previous step</a> to receive a transportation estimate.
      </p>
    </ng-container>
    <!-- </ng-template> -->
  </div>
</div>

<div class="p-4 mt-4" *ngIf="showPhoneNumberInput">
  <div class="d-flex flex-column align-items-center mb-3">
    <i style="font-size: 140px" class="icon-transpo text-grey"></i>

    <h3>Discounted short hauls!</h3>
  </div>
  <div class="form-group form">
    <label for="phone">Don't lose this offer, enter your number to receive 50% off transportation.</label>
    <input
      type="tel"
      mask="(000) 000-0000"
      class="form-control"
      id="phone"
      aria-describedb="phone"
      placeholder="(866) 455-8531"
      (blur)="onPhoneBlur($event.target.value)"
    />
  </div>
</div>
