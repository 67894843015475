import { Storage } from "./storage.model";

export class Image {
  id: string;
  storageModel: Storage;
  imageType: "FullSize" | "Thumbnail";
  description: string;
  name: string;
  relatedImageId: string; // this is the id of the related thumbnail or related fullsize
  partitionKey: string;

  constructor() {
    this.storageModel = new Storage();
    this.imageType = "FullSize";
    this.description = "";
    this.name = "";
  }
}
